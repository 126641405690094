import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../components/mdx/page";
import { Breadcrumbs } from "../components/widgets/breadcrumbs";
import { ButtonPrimary } from "../components/widgets/button";
import { ContentPlusDecor } from "../components/widgets/content-plus-decor";
import { FaBullhorn } from "react-icons/fa";
import { useMembership } from "../utils/member-data";
export const ContactForm = () => <div id="support-form" css={`
            h1 {
                display: none;
            }
        `}>
    </div>;
export const _frontmatter = {};
const layoutProps = {
  ContactForm,
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{`Premium Sign-Up`}</h1>
    <Breadcrumbs to="/sign-up/" text="Sign Up" mdxType="Breadcrumbs" />
    <ContentPlusDecor decor={<FaBullhorn mdxType="FaBullhorn" />} mdxType="ContentPlusDecor">
      <h2>{`Sign up for a Premium Membership`}</h2>
      <p>{`This is the fallback site, and we disabled signups here. Please head over to our `}<a parentName="p" {...{
          "href": "https://turingtrader.com"
        }}>{`main site`}</a>{`, which offers the full functionality.`}</p>
      <p>{`Thank you for your understanding!`}</p>
    </ContentPlusDecor>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      